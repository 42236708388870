export default {
    methods: {
        checkStateName: function(state) {
            if(state === 'paid') {
                return "<span class='text-success cursor-pointer f-12'>Payee</span>";
            } else {
                return "<span class='text-danger cursor-pointer f-12'>Impayee</span>";
            }
        },
        checkStatusName: function(status) {
            if(status === 'pending') {
                return "<span class='text-primary cursor-pointer f-12'>En attente</span>";
            } else if(status === 'ongoing') {
                return "<span class='text-warning cursor-pointer f-12'>En cours</span>";
            } else {
                return "<span class='text-danger cursor-pointer f-12'>Termine</span>";
            }
        },
        checkMethodName: function(method) {
            if(method === 'OM') {
                return "<span class='allogo-badge ombadge'>Orange Money</span>";
            } else if(method === 'MM') {
                return "<span class='allogo-badge mmbadge'>Moov Money</span>";
            } else if(method == 'CM') {
                return "<span class='allogo-badge cmbadge'>Coris Money</span>";
            } else {
                return "<span class='allogo-badge smbadge'>Sank Money</span>";
            }
        },
    }
}