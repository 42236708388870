require('./bootstrap');

window.Vue = require('vue').default;
window.Laravel = { "baseUrl": document.querySelector('meta[name="base-url"]').getAttribute('content')+"/" }

import axios from 'axios';
window.Fire = new Vue();

import VueFeather from 'vue-feather';
Vue.use(VueFeather);

import vmodal from 'vue-js-modal'
Vue.use(vmodal, {dialog:true});

const moment = require('moment')
require('moment/locale/fr')
Vue.use(require('vue-moment'), {
    moment
})

import Swal from 'sweetalert2';
window.Swal = Swal;
const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
});

window.Toast = Toast;

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

import Multiselect from 'vue-multiselect';
Vue.component('multiselect', Multiselect);

import CKEditor from 'ckeditor4-vue';
Vue.use( CKEditor );

import FileSelector from 'vue-file-selector';
Vue.use(FileSelector);

Vue.component('pagination', require('laravel-vue-pagination'));

import Vuesax from 'vuesax'

  import 'vuesax/dist/vuesax.css' //Vuesax styles
  Vue.use(Vuesax)

  import EasySlider from 'vue-easy-slider'
  Vue.use(EasySlider)

import VFileInput from 'v-file-input'
Vue.use(VFileInput)

import VueEasyLightbox from 'vue-easy-lightbox'
Vue.use(VueEasyLightbox)

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
Vue.use(VueTelInput);

import VueFileAgent from 'vue-file-agent';
import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css';

Vue.use(VueFileAgent);

import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload)

import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);



  // import VueRouter from 'vue-router';
  // Vue.use(VueRouter);

  // import LaravelPermissionToVueJS from 'laravel-permission-to-vuejs';
  // Vue.use(LaravelPermissionToVueJS);

// Register Vue Components

Vue.component('example-component', require('./components/ExampleComponent.vue').default);
Vue.component('city-component', require('./components/CityComponent.vue').default);
Vue.component('zone-component', require('./components/ZoneComponent.vue').default);
Vue.component('role-component', require('./components/RoleComponent.vue').default);
Vue.component('partners-component', require('./components/AccountComponent.vue').default);
Vue.component('category-component', require('./components/CategoryComponent.vue').default);
Vue.component('subcategory-component', require('./components/SubCategoryComponent.vue').default);
Vue.component('variation-component', require('./components/VariationComponent.vue').default);
Vue.component('option-component', require('./components/OptionComponent.vue').default);
Vue.component('create-product', require('./components/CreateProductComponent.vue').default);
Vue.component('product-list', require('./components/productListComponent.vue').default);
Vue.component('configure-food', require('./components/FoodComponent.vue').default);
Vue.component('configure-pharmacy', require('./components/PharmacyComponent.vue').default);
Vue.component('configure-supermarket', require('./components/SupermarketComponent.vue').default);
Vue.component('sellpoint-products', require('./components/SellpointProductComponent.vue').default);
Vue.component('supply-form', require('./components/SupplyFormComponent.vue').default);
Vue.component('opened-hour', require('./components/otherInfosComponent.vue').default);
Vue.component('service-list', require('./components/serviceList.vue').default);
Vue.component('service-form', require('./components/creatingServiceForm.vue').default);
Vue.component('services-request', require('./components/clientRequest.vue').default);

Vue.component('commands-list', require('./components/commandes.vue').default);
Vue.component('order-items', require('./components/orderItems.vue').default);

Vue.component('supplies-list', require('./components/Supplies.vue').default);


Vue.component('home-page', require('./components/fronts/home.vue').default);
Vue.component('search-form', require('./components/fronts/home/search.vue').default);
Vue.component('home-slider', require('./components/fronts/slide.vue').default);

Vue.component('restaurants-view', require('./components/fronts/pages/restaurants.vue').default);
Vue.component('pharmacies-view', require('./components/fronts/pages/pharmacies.vue').default);
Vue.component('supermarkets-view', require('./components/fronts/pages/supermarkets.vue').default);

Vue.component('partners-card', require('./components/fronts/home/partnersCardView.vue').default);
Vue.component('partner-details', require('./components/fronts/details/partner.vue').default);
Vue.component('product-categories', require('./components/fronts/home/productCategories.vue').default);
Vue.component('categories-card', require('./components/fronts/home/categoriesComponent.vue').default);
Vue.component('category-view', require('./components/fronts/categories/details.vue').default);

Vue.component('display-cart', require('./components/fronts/cart.vue').default);
Vue.component('display-notification', require('./components/userNotification.vue').default);
// Vue.component('display-food-cart', require('./components/fronts/foodCart.vue').default);

Vue.component('product-details', require('./components/fronts/details/productDetails.vue').default);
Vue.component('cart-view', require('./components/fronts/cart/cartView.vue').default);
Vue.component('checkout-page', require('./components/fronts/cart/checkout.vue').default);

Vue.component('assistance-page', require('./components/fronts/pages/services/assistance.vue').default);
Vue.component('distribution-page', require('./components/fronts/pages/services/distribution.vue').default);
Vue.component('livraison-page', require('./components/fronts/pages/services/livraison.vue').default);
Vue.component('geolocalisation-page', require('./components/fronts/pages/services/geolocalisation.vue').default);

Vue.component('search-result-page', require('./components/fronts/pages/searchResult.vue').default);

Vue.component('partner-products', require('./components/partners/products.vue').default);
Vue.component('partner-orders', require('./components/partners/orders.vue').default);
Vue.component('partner-order-items', require('./components/partners/items.vue').default);
Vue.component('sellpoint-supply-form', require('./components/partners/createSupply.vue').default);
Vue.component('supplies-items', require('./components/partners/suppliesItems.vue').default);

Vue.component('register-form', require('./components/auth/register.vue').default);
Vue.component('registered-partners', require('./components/registrations/registeredPartners.vue').default);

Vue.component('deliveryman-registration', require('./components/auth/deliverymanRegistration.vue').default);
Vue.component('deliveries-list', require('./components/deliveriesList.vue').default);

Vue.component('show-inventories', require('./components/Inventories.vue').default);
Vue.component('partner-inventories', require('./components/PartnerInventories.vue').default);
Vue.component('deliver-orders', require('./components/delivers/orders.vue').default);

Vue.component('telegram-account', require('./components/UserTelegram.vue').default)
// Initialize Vue

Vue.config.productionTip = false;
// import store from "./store";
// import { routes } from './routes';
// import App from './App.vue';

// const router = new VueRouter({
//   mode: 'history',
//   routes
// });

const app = new Vue({
    el: '#app',
    // router,
    //store,
    // render: (h) => h(App),
});

const site = new Vue({
  el: '#site',
});